// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-tag-js": () => import("./../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */),
  "component---src-templates-category-js": () => import("./../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-en-js": () => import("./../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-chambres-dhotes-md": () => import("./../src/pages/chambres-dhotes.md" /* webpackChunkName: "component---src-pages-chambres-dhotes-md" */),
  "component---src-pages-chambres-dhotes-airbnb-nancy-metz-md": () => import("./../src/pages/chambres-dhotes/airbnb-nancy-metz.md" /* webpackChunkName: "component---src-pages-chambres-dhotes-airbnb-nancy-metz-md" */),
  "component---src-pages-contact-success-md": () => import("./../src/pages/contact_success.md" /* webpackChunkName: "component---src-pages-contact-success-md" */),
  "component---src-pages-contact-md": () => import("./../src/pages/contact.md" /* webpackChunkName: "component---src-pages-contact-md" */),
  "component---src-pages-le-chateau-md": () => import("./../src/pages/le-chateau.md" /* webpackChunkName: "component---src-pages-le-chateau-md" */),
  "component---src-pages-le-gite-md": () => import("./../src/pages/le-gite.md" /* webpackChunkName: "component---src-pages-le-gite-md" */),
  "component---src-pages-legal-en-md": () => import("./../src/pages/legal.en.md" /* webpackChunkName: "component---src-pages-legal-en-md" */),
  "component---src-pages-legal-md": () => import("./../src/pages/legal.md" /* webpackChunkName: "component---src-pages-legal-md" */),
  "component---src-pages-les-loisirs-nancy-metz-md": () => import("./../src/pages/les-loisirs-nancy-metz.md" /* webpackChunkName: "component---src-pages-les-loisirs-nancy-metz-md" */),
  "component---src-pages-location-salles-nancy-metz-md": () => import("./../src/pages/location-salles-nancy-metz.md" /* webpackChunkName: "component---src-pages-location-salles-nancy-metz-md" */),
  "component---src-pages-location-salles-nancy-metz-brochure-md": () => import("./../src/pages/location-salles-nancy-metz/brochure.md" /* webpackChunkName: "component---src-pages-location-salles-nancy-metz-brochure-md" */),
  "component---src-pages-location-salles-nancy-metz-mariage-clef-main-a-nancy-md": () => import("./../src/pages/location-salles-nancy-metz/mariage-clef-main-a-nancy.md" /* webpackChunkName: "component---src-pages-location-salles-nancy-metz-mariage-clef-main-a-nancy-md" */),
  "component---src-pages-location-salles-nancy-metz-mariages-md": () => import("./../src/pages/location-salles-nancy-metz/mariages.md" /* webpackChunkName: "component---src-pages-location-salles-nancy-metz-mariages-md" */),
  "component---src-pages-maintenance-md": () => import("./../src/pages/maintenance.md" /* webpackChunkName: "component---src-pages-maintenance-md" */),
  "component---src-pages-newsletter-md": () => import("./../src/pages/newsletter.md" /* webpackChunkName: "component---src-pages-newsletter-md" */),
  "component---src-pages-order-success-md": () => import("./../src/pages/order_success.md" /* webpackChunkName: "component---src-pages-order-success-md" */),
  "component---src-pages-partners-md": () => import("./../src/pages/partners.md" /* webpackChunkName: "component---src-pages-partners-md" */),
  "component---src-pages-cgv-md": () => import("./../src/pages/cgv.md" /* webpackChunkName: "component---src-pages-cgv-md" */)
}

