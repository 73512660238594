import { theme } from '@chakra-ui/core';

const customTheme = {
  ...theme,
  fonts: {
    ...theme.fonts,
    heading: 'Montserrat',
    mono: 'Montserrat',
    body: 'Open Sans',
  },
};

export default customTheme;
